import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const FadeInContainer = ({ duration, children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: duration }}
      style={{ width: "100%" }}
    >
      {children}
    </motion.div>
  );
};

FadeInContainer.propTypes = {
  duration: PropTypes.number
};
FadeInContainer.defaultProps = {
  duration: 1
};

export default FadeInContainer;
