import React from "react";
import PropTypes from "prop-types";
import "./NavBarItem.scss";

const NavBarItem = ({ linkText }) => {
  return <div>{linkText}</div>;
};

NavBarItem.propTypes = {
  linkText: PropTypes.string
};

export default NavBarItem;
