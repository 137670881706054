module.exports = {
  siteTitle: "Shane Makes Website", // <title>
  shortSiteTitle: "ShaneMakesWebsites", // <title> ending for posts and pages
  siteDescription:
    "Hey I'm Shane and I make websites and help with internet marketing & branding. Web developer in Calgary AB Canada. Contact me",
  siteUrl: "https://shanemakeswebsites.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Shane Schmaltz",
  authorTwitterAccount: "shane_schmaltz",

  /* manifest.json */
  manifestName: "ShaneMakesWebsites - I can make you a website",
  manifestShortName: "ShaneMakes", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "#333440",
  manifestThemeColor: "#5CC87C",
  manifestDisplay: "minimal-ui",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/stschmaltz" },
    { name: "twitter", url: "https://twitter.com/shane_schmaltz" },
    { name: "facebook", url: "http://facebook.com/greglobinski" },
    { name: "linkedIn", url: "https://www.linkedin.com/in/stschmaltz/" }
  ]
};
