import React from "react";
import PropTypes from "prop-types";
import "./NavBar.scss";
import NavBarItem from "./NavBarItem";
const NavBar = props => {
  return (
    <div className="nav-bar">
      <div className="nav-bar-logo" />
      <div className="nav_link-container">
        <NavBarItem linkText="Services" />
        <NavBarItem linkText="Get Started" />
        <NavBarItem linkText="About Me" />
        <NavBarItem linkText="Website Tips" />
        <NavBarItem linkText="Contact" />
      </div>
    </div>
  );
};

NavBar.propTypes = {};

export default NavBar;
